import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import AlertsPage from '../../container/pages/AlertPage';

const AlertDetail = lazy(() => import('../../container/pages/alertsPage/AlertDetailPage'));
const NotFound = lazy(() => import('../../container/pages/404'));

function AlertsRoute() {
  return (
    <Routes>
      <Route path="/" element={<AlertsPage />} />
      <Route path="/:id" element={<AlertDetail />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AlertsRoute;
