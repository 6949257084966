import React, { useState } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import UilAngleLeftB from '@iconscout/react-unicons/icons/uil-angle-left-b';
import search from '../../../static/img/icon/search.svg';

function AlertPageHeader({ type }) {
  const [selectedImportance, setSelectedImportance] = useState(null);
  const handleImportanceChange = (value) => {
    setSelectedImportance(value);
  };
  return (
    <>
      <div className="w-full flex items-start justify-between">
        <div className="w-96 flex items-center">
          <Link to="/admin/alerts/" className={`${type ? 'hidden' : 'flex'}`}>
            <UilAngleLeftB />
          </Link>
          <h1 className="mt-0 mb-0 ml-3 text-white font-semibold text-3xl">All notifications</h1>
        </div>

        <div className={`${type ? 'flex' : 'hidden'} items-center justify-between`}>
          <Select
            className="cursor-pointer w-44 rounded-lg border-[1px] border-[rgba(255,255,255,0.28)] !bg-transparent text-white"
            showSearch
            placeholder="Dates"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options={[
              {
                value: '1',
                label: 'test',
              },
              {
                value: '2',
                label: 'Lucy',
              },
            ]}
          />

          <Select
            className="ml-5 cursor-pointer w-44 rounded-lg border-[0.6px] border-[#D5D5D5] outline-none !bg-transparent text-white"
            placeholder="All plans"
            onChange={handleImportanceChange}
            value={selectedImportance}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options={[
              { value: '1', label: 'high' },
              { value: '2', label: 'medium' },
              { value: '3', label: 'low' },
            ]}
          />
          <div className="relative ml-5">
            <input
              className="rounded  border-[0.6px] border-[#D5D5D5] bg-[#1C1E2B] py-2 outline-none w-60 pl-8 text-white "
              type="search"
              placeholder="Search"
            />
            <img className="absolute left-2 top-2.5" src={search} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

AlertPageHeader.propTypes = {
  type: PropTypes.bool.isRequired,
};

export default AlertPageHeader;
