import React, { useEffect, useState, Suspense } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
// import Swal from 'sweetalert2';
// import withReactContent from 'sweetalert2-react-content';
import { Spin, Pagination, message, Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import AlertCard from './alertsPage/AlertCard';
import AlertPageHeader from './alertsPage/AlertPageHeader';

function AlertsPage() {
  // const { id } = useParams();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  // const MySwal = withReactContent(Swal);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const token = Cookies.get('access_token');
        const response = await axios.get('https://backend.bizdin.ai/api/notification/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setNotifications(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          message.error('Unauthorized. Please log in.');
        } else {
          message.error('An error occurred while fetching notifications.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen m-5 md:m-8 p-6">
        <Skeleton />
        <Spin className="mt-5" size="large" tip="Загрузка уведомлений..." />
      </div>
    );
  }

  console.log(notifications);

  // MySwal.fire({
  //   position: 'top-end',
  //   icon: 'error',
  //   html: '<div class="font-bold">salam</div>',
  //   showConfirmButton: false,
  //   timer: 1500,
  // });

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentData = notifications.slice(startIndex, startIndex + itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="m-5 md:m-8 p-6">
      <AlertPageHeader type />
      <p
        style={{ borderBottom: '1px solid #8331D3', width: '17px' }}
        className="text-[#8331D3] border-2 border-t-[#8331D3] cursor-pointer"
      >
        All
      </p>
      <div className="p-[16px_16px_8px_16px] rounded-lg bg-[rgba(255,255,255,0.06)] flex flex-wrap justify-between">
        <Suspense fallback={<Spin size="large" tip="Загрузка уведомлений..." />}>
          {currentData.map((notification) => (
            <Link key={notification.id} to={`/admin/alerts/${notification.id}`} className="w-[48%]">
              <AlertCard
                key={notification.id}
                title={notification.title}
                image={notification.images}
                createdAt={notification.created_at}
              />
            </Link>
          ))}
        </Suspense>
      </div>
      <div className="flex justify-center mt-4">
        <Pagination
          current={currentPage}
          pageSize={itemsPerPage}
          total={notifications.length}
          onChange={handlePageChange}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
}

AlertCard.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
};

export default AlertsPage;
