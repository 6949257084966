import React, { createContext, useEffect, useState, useMemo } from 'react';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { notification } from 'antd';

export const WebSocketContext = createContext();

function WebSocketProvider({ children }) {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    let ws;
    const connect = () => {
      const token = Cookies.get('access_token');
      ws = new WebSocket(`wss://backend.bizdin.ai/ws/notifications/?token=${token}`);

      ws.onopen = () => {
        console.log('WebSocket connected');
      };

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        setNotifications((prev) => [...prev, data]);
        console.log('Сырые данные:', event.data);
        notification.info({
          message: data.notification.title,
          description: data.notification.message || 'Уведомление без текста',
          placement: 'topRight',
          duration: 5,
        });
      };

      ws.onclose = () => {
        console.log('WebSocket disconnected. Reconnecting...');
        setTimeout(connect, 5000); // Переподключение через 5 секунд
      };

      ws.onerror = (error) => console.error('WebSocket error:', error);
    };

    connect();

    return () => ws && ws.close();
  }, []);

  const contextValue = useMemo(() => ({ notifications }), [notifications]);

  return <WebSocketContext.Provider value={contextValue}>{children}</WebSocketContext.Provider>;
}

WebSocketProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WebSocketProvider;
