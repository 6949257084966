import React from 'react';
import PropTypes from 'prop-types';
import face from '../../../static/img/icon/Face Scan Square.svg';

function AlertCard({ title, image, createdAt }) {
  return (
    <>
      <div
        style={{ border: '1px solid rgba(255,255,255,0.19)', padding: '16px' }}
        className="mb-8 w-full flex items-start rounded-xl border-2 border-[rgba(255,255,255,0.19)]"
      >
        <img className="rounded w-[121px] h-[121px] object-cover" src={image} alt="" />
        <div className="flex flex-col items-start ml-7">
          <div className="flex items-center p-[8px] bg-[rgba(255,255,255,0.19)] rounded-lg">
            <img src={face} alt="" />
            <p className="mt-0 mb-0 ml-2 text-[#BFC4CC] font-semibold tracking-[0.3px] ">Face</p>
          </div>

          <p className="text-white">Description</p>
          <p className="text-[#BFC4CC] font-semibold tracking-[0.3px] ">{createdAt.split('T')[0]}</p>
          <p className="text-[#BFC4CC] font-semibold tracking-[0.3px] ">{title}</p>
        </div>
      </div>
    </>
  );
}

AlertCard.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
};

export default AlertCard;
