import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import PlansPage from '../../container/pages/plansPage/PlansPage';

const PlansList = lazy(() => import('../../container/pages/plansPage/plansList'));
const PlansCreate = lazy(() => import('../../container/pages/plansPage/plansCreate'));
const PlansEditForm = lazy(() => import('../../container/pages/plansPage/plansEditForm'));
const NotFound = lazy(() => import('../../container/pages/404'));

function PlansRoute() {
  return (
    <Routes>
      <Route path="/" element={<PlansPage />} />
      <Route path="/:id" element={<PlansList />} />
      <Route path="/edit/:id" element={<PlansEditForm />} />
      <Route path="/add-plan" element={<PlansCreate />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PlansRoute;
