import React, { useState } from 'react';
import { Select } from 'antd';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';
import { Link } from 'react-router-dom';
import PlansCard from '../../../components/plans/plansCard';
import search from '../../../static/img/icon/search.svg';

function PlansPage() {
  const [scenarioCount, setScenarioCount] = useState(0);
  const [selectedImportance, setSelectedImportance] = useState(null);
  const handleImportanceChange = (value) => {
    setSelectedImportance(value);
  };

  const handleDataLoad = (count) => {
    setScenarioCount(count);
  };
  return (
    <div className="m-5 md:m-8 p-6 min-h-[calc(100vh-96px)] rounded-3xl bg-[#111321]">
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center">
          <h1 className="text-white text-[32px] font-semibold">All sceanarios:</h1>
          <p className="text-[#828282] ml-3">{scenarioCount} sceanarios</p>
          <div className="bg-[#8331D3] ml-3 rounded-xl">
            <Link to="/admin/plans/add-plan" className="p-2 flex items-center justify-center cursor-pointer">
              <UilPlus className="text-white" />
            </Link>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <Select
            className="cursor-pointer w-44 rounded-lg border-[1px] border-[rgba(255,255,255,0.28)] !bg-transparent text-white"
            showSearch
            placeholder="Select a camera"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options={[
              {
                value: '1',
                label: 'test',
              },
              {
                value: '2',
                label: 'Lucy',
              },
            ]}
          />

          <Select
            className="ml-5 cursor-pointer w-44 rounded-lg border-[0.6px] border-[#D5D5D5] outline-none !bg-transparent text-white"
            placeholder="Importance"
            onChange={handleImportanceChange}
            value={selectedImportance}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options={[
              { value: '1', label: 'high' },
              { value: '2', label: 'medium' },
              { value: '3', label: 'low' },
            ]}
          />
          <div className="relative ml-5">
            <input
              className="rounded border-[0.6px] border-[#D5D5D5] bg-[#1C1E2B] py-2 outline-none w-60 pl-8 text-white"
              type="search"
              placeholder="Search"
            />
            <img className="absolute left-2 top-2.5" src={search} alt="" />
          </div>
        </div>
      </div>
      <PlansCard onDataLoad={handleDataLoad} importance={selectedImportance} />
    </div>
  );
}

export default PlansPage;
