import React, { useState, useEffect } from 'react';
import { Table, Tag, Modal, message } from 'antd';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Link } from 'react-router-dom';
import UilEllipsisH from '@iconscout/react-unicons/icons/uil-ellipsis-h';
import UilEye from '@iconscout/react-unicons/icons/uil-eye';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import UilTrashAlt from '@iconscout/react-unicons/icons/uil-trash-alt';
import { TableWrapper } from '../../container/styled';
import { Dropdown } from '../dropdown/dropdown';

function PlansCard({ onDataLoad, importance }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleDelete = async (id) => {
    try {
      const token = Cookies.get('access_token');
      await axios.delete(`https://backend.bizdin.ai/plans/my/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      message.success('Сценарий успешно удалён');
      setData((prevData) => prevData.filter((item) => item.id !== id));
    } catch (error) {
      message.error('Ошибка при удалении сценария');
    }
  };

  const actions = (record) => (
    <>
      <Link to={`${record.id}`}>
        <UilEye />
        <span>View</span>
      </Link>
      <Link to={`edit/${record.id}`}>
        <UilEdit />
        <span>Edit</span>
      </Link>
      <Link
        to="#"
        onClick={() => {
          Modal.confirm({
            title: 'Вы уверены, что хотите удалить этот сценарий?',
            onOk: () => handleDelete(record.id),
            okText: 'Удалить',
            cancelText: 'Отмена',
          });
        }}
      >
        <UilTrashAlt />
        <span>Delete</span>
      </Link>
    </>
  );

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const token = Cookies.get('access_token');
        const response = await axios.get('https://backend.bizdin.ai/plans/my/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data);
        if (onDataLoad) {
          onDataLoad(response.data.length);
        }
      } catch (error) {
        message.error('Ошибка при загрузке данных');
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, [onDataLoad]);

  const filteredData = importance ? data.filter((item) => item.criticality === importance) : data;

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Criticality',
      dataIndex: 'criticality',
      key: 'criticality',
      render: (criticality) => {
        const color = criticality === 'low' ? 'green' : criticality === 'medium' ? 'orange' : 'volcano';
        return (
          <Tag color={color} key={criticality}>
            {criticality.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (createdAt) => createdAt.split('T')[0],
    },
    {
      title: 'Updated At',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (updatedAt) => updatedAt.split('T')[0],
    },
    {
      title: 'Cameras',
      dataIndex: 'cameras',
      key: 'cameras',
      render: (cameras) => cameras.length,
    },
    {
      title: 'Action',
      key: 'action',
      render: (record) => (
        <div className="edit">
          <Dropdown content={actions(record)} trigger={['click']} className="wide-dropdown ">
            <Link className="card__more_actions" to="#">
              <UilEllipsisH />
            </Link>
          </Dropdown>
        </div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.title === 'Disabled User',
      title: record.title,
    }),
  };

  const [selectionType, setSelectionType] = useState('checkbox');
  console.log(setSelectionType);
  return (
    <>
      <div className="ninjadash-support-content-table mt-9 rounded-2xl">
        <TableWrapper className="table-data-view table-responsive">
          <Table
            loading={loading}
            pagination={{ pageSize: 10, showSizeChanger: true }}
            rowSelection={{
              type: selectionType,
              ...rowSelection,
            }}
            dataSource={filteredData.map((item) => ({ ...item, key: item.id }))}
            columns={columns}
          />
        </TableWrapper>
      </div>
    </>
  );
}

PlansCard.propTypes = {
  onDataLoad: PropTypes.func.isRequired,
  importance: PropTypes.string,
};
export default PlansCard;
