import { fetchWithAuth } from '../../container/pages/fetchWithAuth';

export const fetchEmployees = async () => {
  const employeesData = await fetchWithAuth('GET', 'https://backend.bizdin.ai/employees');
  const formattedEmployees = employeesData.map((employee) => ({
    id: employee.id,
    fullName: employee.full_name,
    dateOfBirth: employee.date_birth,
    iin: employee.iin,
    document: employee.document,
    image: employee.image,
  }));

  return formattedEmployees;
};
