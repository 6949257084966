import {
  UilCircle,
  UilCamera,
  UilSchedule,
  // UilDesktop,
  UilMap,
  // UilCalender,
  UilPlay,
  UilCalendarSlash,
  // UilDocumentInfo,
  // UilBus,
  UilChatBubbleUser,
} from '@iconscout/react-unicons';

import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const path = '/admin';
  const pathName = window.location.pathname;
  const pathArray = pathName && pathName !== '/' ? pathName.split(path) : [];
  const mainPath = pathArray.length > 1 ? pathArray[1] : '';
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const items = [
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/pages/dashboard`}>
        {t('dashboard')}
      </NavLink>,
      'dashboard',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/pages/dashboard`}>
          <UilSchedule />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/pages/alerts`}>
        {t('alerts')}
      </NavLink>,
      'alerts',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/pages/alerts`}>
          <UilCircle />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/pages/videotest`}>
        {t('video test')}
      </NavLink>,
      'videotest',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/pages/videotest`}>
          <UilMap />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink className="rounded-2xl" onClick={toggleCollapsed} to={`${path}/pages/cameras`}>
        {t('cameras')}
      </NavLink>,
      'cameras',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/pages/cameras`}>
          <UilCamera />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/users`}>
        {t('users')}
      </NavLink>,
      'users',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/users`}>
          <UilChatBubbleUser />
        </NavLink>
      ),
    ),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/pages/sensors`}>
    //     {t('sensors')}
    //   </NavLink>,
    //   'sensors',
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/pages/sensors`}>
    //       <UilDesktop />
    //     </NavLink>
    //   ),
    // ),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/pages/map`}>
    //     {t('map')}
    //   </NavLink>,
    //   'map',
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/pages/map`}>
    //       <UilMap />
    //     </NavLink>
    //   ),
    // ),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/pages/events`}>
    //     {t('events')}
    //   </NavLink>,
    //   'events',
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/pages/events`}>
    //       <UilCalender />
    //     </NavLink>
    //   ),
    // ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/pages/videogpt`}>
        {t('video')} {t('gpt')}
      </NavLink>,
      'video gpt',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/pages/videogpt`}>
          <UilPlay />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/plans`}>
        {t('plans')}
      </NavLink>,
      'plans',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/plans`}>
          <UilCalendarSlash />
        </NavLink>
      ),
    ),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/pages/reports`}>
    //     {t('reports')}
    //   </NavLink>,
    //   'reports',
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/pages/reports`}>
    //       <UilDocumentInfo />
    //     </NavLink>
    //   ),
    // ),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/pages/train`}>
    //     {t('train')}
    //   </NavLink>,
    //   'train',
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/pages/train`}>
    //       <UilBus />
    //     </NavLink>
    //   ),
    // ),
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
